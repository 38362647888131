import React from "react"

import RegisterLayout from "../components/Layout/RegisterLayout"
import ResetPasswordComponent from "../components/LoginRegister/ResetPasswordComponent"

const ResetPassword = () => {
  return (
    <RegisterLayout isFooter={true}>
      <ResetPasswordComponent />
    </RegisterLayout>
  )
}

export default ResetPassword

import React, { useContext, useState } from "react"

import { AuthenticationContext } from "../../context"
import { toastMessage, TYPE_ERROR } from "../UI/Toast"

import "./ResetPasswordComponent.scss"

const ResetPasswordComponent = () => {
  const initialValue = {
    password: "",
    passwordConfirm: "",
  }

  const { resetPassword, isLoadingRequest, setIsLoadingRequest } = useContext(
    AuthenticationContext
  )
  const [value, setValues] = useState(initialValue)

  const getParams = () => {
    let dataParams = {}
    const params = new URLSearchParams(window.location.search)
    if (
      params.has("access-token") &&
      params.has("client") &&
      params.has("uid") &&
      params.has("reset_password")
    ) {
      const accessToken = params.get("access-token")
      const client = params.get("client")
      const uid = params.get("uid")
      const resetPassword = params.get("reset_password")
      dataParams = {
        "access-token": accessToken,
        client: client,
        reset_password: resetPassword,
        uid: uid,
      }
    }
    return dataParams
  }

  const handleInputChange = (e) => {
    setIsLoadingRequest(false)
    const { name, value } = e.target

    setValues((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (value.password === value.passwordConfirm) {
      const dataParams = getParams()
      resetPassword(dataParams, value.password)
    } else {
      toastMessage(
        "Password and Confirm Password values should be same",
        TYPE_ERROR
      )
    }
  }

  return (
    <div className="reset-password-container">
      <div className="reset-password-component">
        <div className="reset-password-component__body">
          <div className="reset-password-component__body__heading">
            <p>Reset Password</p>
          </div>
          <div className="reset-password-component__body__password">
            <form onSubmit={handleSubmit} id="reset-form">
              <div>
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  name="password"
                  required
                  placeholder="Please Enter"
                  value={value.password}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="passwordConfirm">Confirm Password</label>
                <input
                  type="password"
                  name="passwordConfirm"
                  required
                  placeholder="Please Enter"
                  value={value.passwordConfirm}
                  onChange={handleInputChange}
                />
              </div>
            </form>
          </div>
          <div className="reset-password-component__body__submit">
            <button type="submit" form="reset-form" disabled={isLoadingRequest}>
              Reset your password
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPasswordComponent
